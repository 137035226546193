import { Deserializable } from "./deserialize.model";

export interface IUser {
  id?: string;
  fullname?: string;
  role?: string;
  password?: string;
}

export interface IUserInstitucion {
  estado: number
  id: number
  nombre: string
}
export interface IUserFacultad {
  estado: number
  id: number
  nombre: string
  id_institucion: IUserInstitucion
}
export interface IUserCarrera{
  estado: number
  id: number
  nombre: string
  id_facultad: IUserFacultad
}

export interface IUserRol{
  descripcion: string
  estado: number
  id: number
}
export interface IUserData {
  id: number
  codigo: string
  username: string
  password: string
  email: string
  first_name: string
  last_name: string
  is_active: boolean
  id_carrera: IUserCarrera
  role: IUserRol
}

export class User implements IUser, Deserializable{
  id?: string;
  fullname?: string;
  role?: string;
  password?: string;

  constructor(user: any) {
    this.id = user.id;
    this.fullname = user.fullname;
    this.role = user.role;
    this.password = user.password;
  }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
