import { Routes } from '@angular/router';
import { config } from 'config';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
// import { AuthGuard } from './shared/guards/auth.guard';
import { UserRoleGuard } from './shared/guards/user-role.guard';
import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';


export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'sessions/signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [UserRoleGuard],
    data: {roles: config.authRoles.user},
    children: [
      {
        path: 'mi-perfil',
        loadChildren: () => import('./views/miperfil/miperfil.module').then(m => m.MiPerfilModule),
        //data: { title: 'Perfiles', breadcrumb: 'PERFILES'}
      },
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [UserRoleGuard],
    data: {roles: config.authRoles.editor},
    loadChildren: () => import('./views/home-handler/home-handler.module').then(m => m.HomeHandlerModule),
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [UserRoleGuard],
    data: {roles: config.authRoles.sa},
    children: [
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
      //   //data: { title: 'Dashboard', breadcrumb: 'DASHBOARD', roles: config.authRoles.sa}
      // },
      {
        path: 'perfiles',
        loadChildren: () => import('./views/perfiles/perfiles.module').then(m => m.PerfilesModule),
        //data: { title: 'Perfiles', breadcrumb: 'PERFILES'}
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./views/usuarios/usuarios.module').then(m => m.UsuariosModule),
        //data: { title: 'Usuarios', breadcrumb: 'USUARIOS'}
      },
      {
        path: 'instituciones',
        loadChildren: () => import('./views/instituciones/instituciones.module').then(m => m.InstitucionesModule),
        //data: { title: 'Instituciones', breadcrumb: 'INSTITUCIONES'}
      },
      {
        path: 'competencias',
        loadChildren: () => import('./views/competencias/competencias.module').then(m => m.CompetenciasModule),
        //data: { title: 'Competencias', breadcrumb: 'COMPETENCIAS'}
      },
      {
        path: 'bloques',
        loadChildren: () => import('./views/bloques/bloques.module').then(m => m.BloquesModule),
        //data: { title: 'Bloques', breadcrumb: 'BLOQUES'}
      },
      {
        path: 'examenes_',
        loadChildren: () => import('./views/examenes/examenes.module').then(m => m.ExamenesModule),
        //data: { title: 'Examenes', breadcrumb: 'EXAMENES'}
      },
      {
        path: 'examenes',
        loadChildren: () => import('./views/examenes2/examenes.module').then(m => m.ExamenesModule),
        //data: { title: 'Examenes', breadcrumb: 'EXAMENES'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [UserRoleGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {roles: config.authRoles.user},
    children: [
      {
        path: 'inicio',
        loadChildren: () => import('./views/inicio/inicio.module').then(m => m.InicioModule),
      },
      {
        path: 'diagnosticos',
        loadChildren: () => import('./views/diagnosticos/diagnosticos.module').then(m => m.DiagnosticosModule),
      },
      {
        path: 'dashboard-alumno',
        loadChildren: () => import('./views/dashboard-alumno/dashboard-alumno.module').then(m => m.DashboardAlumnoModule),
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

